import serviceClause from 'views/appView/serviceClause'
import privacyClause from 'views/appView/privacyClause'
import gameRule from 'views/appView/gameRule'
import deregistration from 'views/appView/deregistration'
import gameInfo from 'views/appView/gameInfo'

const routes = () => [
  {
    path: '/appView/serviceClause',
    component: serviceClause,
    exact: true,
  },
  {
    path: '/appView/privacyClause',
    component: privacyClause,
    exact: true,
  },
  {
    path: '/appView/gameRule',
    component: gameRule,
    exact: true,
  },
  {
    path: '/appView/deregistration',
    component: deregistration,
    exact: true,
  },
  {
    path: '/appView/gameInfo/:gameId',
    component: gameInfo,
    exact: false,
  },
]

export default routes
